export default {
  head () {
    return {
      title: this.page.custom_fields ? this.page.custom_fields.page_title : '',
      meta: [
        { hid: 'description', name: 'description', content: this.page.custom_fields ? this.page.custom_fields.meta_description : '' }
      ]
    }
  }
}
