<template>
  <div>
    <intro-section
      v-if="page.custom_fields"
      :hero-banner="page.custom_fields.hero_banner"
      :hero-image="page.custom_fields.hero_product_image"
      :hero-title="page.custom_fields.hero_title"
      :hero-description="page.custom_fields.hero_description"
      :hero-button-text="page.custom_fields.button_text"
      :hero-button-link="page.custom_fields.button_link"
      @openModal="toggleModal"></intro-section>

    <div class="info-module__container">
      <info-module
        v-for="(module, moduleIndex) in page.custom_fields.info_modules"
        :key="moduleIndex"
        :background-image="module.background_image"
        :info-title="module.title"
        :button-text="module.button_text"
        :link-to="module.button_link"
        :is-new="module.is_new"
        :font-color="module.font_color"
      />
    </div>

    <div
      :style="`background-image: url(${page.custom_fields.how_it_works_background});`"
      class="how-it-works__container"
    >
      <div class="how-it-works__main-content">
        <h4>How Does AquaBed Work?</h4>
        <p>The AquaBed utilizes water-circulation to distribute heating and cooling effects to the bed. The AquaBed eliminates all electric wires, fire hazards, and EMFs from the sleeping area, keeping you warm and safe.</p>
        <button
          class="button more-info-button"
          @click="toggleModal">Watch Video</button>
      </div>
    </div>

    <section class="reviews__section">
      <h1>Reviews</h1>

      <div class="reviews__container">
        <review-item
          v-for="(review, reviewIndex) in shuffledReviews"
          v-if="reviewIndex < 3"
          :key="reviewIndex"
          :review-text="review.custom_fields.review"
          :reviewer="review.custom_fields.reviewer_name"
          :star-rating="parseInt(review.custom_fields.star_rating)"
          class="review-item-container"
        />
      </div>

      <!-- See more on Amazon callout
      ==================================================-->
      <div class="see-more-reviews__container">
        <span>
          See more reviews on
          <a
            href="https://www.amazon.com/dp/B07KKB2XC8/ref=twister_B07KK8C3V8?_encoding=UTF8&amp;psc=1"
            target="_blank"
          >
            <img
              src="/img/amazon_logo_white.png"
              class="amazon-logo-white"
              alt="Aqua bed warmer on Amazon"
              scale="0"
            >
          </a>
        </span>
      </div>
    </section>

    <video-modal
      :display-modal="displayModal"
      @closeModal="displayModal = false"/>
  </div>
</template>

<script>
import { shuffle } from 'lodash'

import IntroSection from '~/components/homepage/IntroSection'
import InfoModule from '~/components/homepage/InfoModule'
import ReviewItem from '~/components/homepage/ReviewItem'
import videoModal from '~/components/homepage/VideoModal'
import head from '~/mixins/headerMetaData'

export default {
  name: 'Home',
  components: {
    IntroSection,
    InfoModule,
    ReviewItem,
    videoModal
  },
  mixins: [head],
  async asyncData (context) {
    const pageExists = context.store.getters['pages/doesPageExist']('home')
    if (pageExists) {
      const pageId = context.store.getters['pages/pageIdFromSlug']('home')
      let { data } = await context.$axios.get(`/api/pages/${pageId}`).catch((err) => {
        console.log(err)
      })
      if (data) {
        return {
          page: data,
          displayModal: false
        }
      }
    } else {
      // If no page exists set the ID to false
      return {
        page: {
          ID: false,
          displayModal: false
        }
      }
    }
  },
  computed: {
    shuffledReviews () {
      if (this.page) {
        return shuffle(this.page.custom_fields.reviews)
      } else {
        return []
      }
    }
  },
  methods: {
    toggleModal () {
      if (this.displayModal) {
        this.displayModal = false
      } else {
        this.displayModal = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.info-module {
  @include e(container) {
    width: 100%;
    margin: 15px auto 0;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
}

.how-it-works {
  @include e(container) {
    width: 100%;
    margin: 0 0% 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @media screen and (max-width: 600px) {
      width: 100%;
      margin: 0px 0px 15px;
    }
  }

  @include e(main-content) {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 120px 4.5%;

    .button {
      box-shadow: 5px 5px 12px 5px rgba(10, 10, 10, 0.25);
    }

    h4 {
      color: #fff;
      font-size: 35px;
      margin-top: 0;
      margin-bottom: 25px;
    }

    p {
      max-width: 600px;
      margin: 0 auto 30px;
    }

    @media screen and (max-width: 850px) {
      padding: 75px 4.5%;

      h4 {
        font-size: 24px;
      }
    }
  }
}

.reviews {
  @include e(section) {
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../static/img/water-background.jpg");
    background-position: 50% 50%;
    color: #fff;
    padding: 60px 7% 60px;

    h1 {
      color: #fff;
      text-align: center;
      font-size: 35px;
      margin-bottom: 35px;
    }

    @media screen and (max-width: 850px) {
      padding: 45px 7% 45px;

      h1 {
        font-size: 24px;
      }
    }
  }

  @include e(container) {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 45px;
    position: relative;

    @media screen and (max-width: 850px) {
      flex-direction: column;
      margin: 0 auto 45px;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -25px;
      width: 100px;
      height: 1px;
      left: -5%;
      right: -5%;
      margin: 0 auto;
      background: #fff;
    }
  }
}

.see-more-reviews {
  @include e(container) {
    width: 100%;
    margin: 0 auto;
    text-align: center;

    span {
      position: relative;
    }

    a {
      position: relative;
      top: 15px;
      margin-left: 10px;
    }

    img {
      max-width: 90px;
    }
  }
}
</style>
