<template>
  <div
    :style="`background-image:url(${backgroundImage});`"
    class="info-module__item">
    <div class="info-module__content">
      <div class="info-module__is-new">
        <span
          v-if="isNew"
          :style="`color: ${fontColor};`"
          class="new-icon">New</span>
      </div>
      <h3 :style="`color: ${fontColor};`">{{ infoTitle }}</h3>
      <nuxt-link
        :to="linkTo"
        class="button more-info-button button-shadow">{{ buttonText }}</nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    backgroundImage: {
      type: String,
      default: ''
    },
    infoTitle: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    linkTo: {
      type: String,
      default: ''
    },
    isNew: {
      type: Boolean,
      default: false
    },
    fontColor: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style lang="scss" scoped>
.info-module {
  @include e(item) {
    width: calc((100% - 15px) / 2);
    margin-bottom: 15px;
    background-size: cover;
    background-position: 100% 50%;

    &:nth-child(1) {
      margin-right: 15px;
    }

    &:nth-child(2) {
      margin-left: 0%;
      margin-right: 0%;
    }

    .new-icon {
      display: block;
      width: 75px;
      border: 2px solid #fff;
      border-radius: 7px;
      padding: 2px 7px;
      font-size: 20px;
      text-align: center;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
      margin: 0px 0px 15px 0px;
      background-position: 100% 50%;

      .new-icon {
        margin: 0 auto;
      }
    }
  }

  @include e(is-new) {
    height: 32px;
  }

  @include e(content) {
    height: 100%;
    width: 55%;
    min-width: 300px;
    margin: 0 0 0px 0;
    color: #fff;
    padding: 45px 30px 60px 60px;
    background: transparent;

    h3 {
      font-size: 35px;
      margin: 15px 0px 30px 0px;
    }

    .button {
      min-width: 218px;
    }

    @media screen and (max-width: 850px) {
      width: 100%;
      text-align: center;
      margin: 0 auto;
      background: rgba(0, 0, 0, 0.5);
      padding: 45px 30px;

      span {
        margin: 0 auto;
        text-align: center;
      }

      h3 {
        font-size: 24px;
      }
    }
  }
}
</style>
