<template>
  <section
    :style="`background-image: url(${heroBanner});`"
    class="intro-section">
    <!-- Intro section & buy callout
    ==================================================-->
    <div class="intro-info-container">
      <h1>{{ heroTitle }}</h1>
      <p>{{ heroDescription }}</p>
      <router-link
        :to="heroButtonLink"
        class="button more-info-button button-shadow"
      >{{ heroButtonText }}</router-link>
    </div>

    <div class="intro__product-display">
      <img
        :src="heroImage.url"
        :alt="heroImage.alt">
    </div>
  </section>
</template>

<script>
export default {
  props: {
    heroBanner: {
      type: String,
      default: ''
    },
    heroImage: {
      type: Object,
      default: function () {
        return {}
      }
    },
    heroTitle: {
      type: String,
      default: ''
    },
    heroDescription: {
      type: String,
      default: ''
    },
    heroButtonText: {
      type: String,
      default: ''
    },
    heroButtonLink: {
      type: String,
      default: ''
    }
  },
  methods: {
    openModal () {
      this.$emit('openModal')
    }
  }
}
</script>

<style lang="scss" scoped>
/*--------------------------------------------------------------
# Intro section
--------------------------------------------------------------*/
.intro-section {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 120px 4.5% 90px 7%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1220px) {
    padding: 75px 4.5% 60px;
  }

  @media screen and (max-width: 850px) {
    flex-direction: column;
    padding: 15px 4.5% 0px;
  }

  .intro__product-display {
    width: 50%;
    position: absolute;
    right: -5px;
    bottom: 0;
    margin: 0 auto;
    text-align: center;

    img {
      width: 100%;
      margin-bottom: -3px;
    }

    @media screen and (max-width: 1220px) {
      width: 55%;
    }

    @media screen and (max-width: 850px) {
      width: 100%;
      position: inherit;
      margin-bottom: 0px;
      right: 0px;

      img {
        width: 400px;
        margin: 0 auto;
        display: block;
      }
    }
  }

  .intro-info-container {
    width: 50%;
    color: $main-font-color;

    h1 {
      font-size: 50px;
      color: $main-font-color;
    }

    p {
      font-size: 18px;
      line-height: 1.6em;
      color: $main-font-color;
    }

    @media screen and (max-width: 1220px) {
      width: 45%;

      h1 {
        font-size: 35px;
      }
    }

    @media screen and (max-width: 850px) {
      width: 100%;
      max-width: 660px;
      margin: 0;
      padding: 15px 0px;
      text-align: center;

      h1 {
        font-size: 30px;
      }

      p {
        font-size: 16px;
      }
    }

    .button {
      min-width: 218px;
    }
  }
}
</style>
