<template>
  <div
    v-if="displayModal"
    class="simple-modal-wrapper"
    data-modal-id="aqua-video"
    data-animation-duration="500"
    @click="closeModal"
  >
    <div class="modal-content">
      <div class="modal-content video-wrap">
        <span
          class="close-modal simple-modal-close"
          @click="closeModal"></span>
        <iframe
          src="https://www.youtube.com/embed/Q5pRm1eo4ZM?showinfo=0"
          frameborder="0"
          class="aquabed-video"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    displayModal: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.simple-modal-wrapper,
.simple-modal-wrapper * {
  padding: 0;
  margin: 0;
}

.simple-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(48, 48, 48, 0.8);
}

.modal-content {
  width: 80%;
  height: 80%;
  max-width: 1000px;
  min-height: 400px;
  max-height: 750px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.close-modal {
  position: absolute;
  top: -30px;
  right: 7px;
  padding: 12px;
  background-image: url("/img/x_button.png");
  background-position: center center;
  background-size: contain;
  cursor: pointer;
  vertical-align: middle;
  color: #fff;
  line-height: 0.5em;
  font-size: 2em;
  font-weight: normal;
  transition: color 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  opacity: 0.5;
  z-index: 3000;
}

.close-modal:hover {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

// Iframe video
.video-wrap {
  width: 100%;
  padding-top: 10%;
  padding-bottom: 10%;

  .aquabed-video {
    width: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 460px) {
  .modal-content {
    width: 90%;
  }
}

@media screen and (min-width: 806px) {
  .modal-content {
    width: 90%;
  }
}

@media screen and (min-width: 1024px) {
  .modal-content {
    width: 80%;
    height: 80%;
  }
}
</style>
