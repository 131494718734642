<template>
  <div class="review-item-container">
    <h4>"{{ reviewText }}"</h4>
    <div class="star-rating">
      <font-awesome-icon
        class="star-rating"
        icon="star"/>
      <font-awesome-icon
        class="star-rating"
        icon="star"/>
      <font-awesome-icon
        class="star-rating"
        icon="star"/>
      <font-awesome-icon
        class="star-rating"
        icon="star"/>
      <font-awesome-icon
        class="star-rating"
        icon="star"/>
    </div>
    <p>{{ reviewer }}</p>
  </div>
</template>

<script>
export default {
  props: {
    reviewText: {
      type: String,
      default: ''
    },
    starRating: {
      type: Number,
      default: 5
    },
    reviewer: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.review-item-container {
  width: 30%;
  margin-right: 5%;
  text-align: center;
  color: #fff;

  &:nth-child(3n) {
    margin-right: 0;
  }

  h4 {
    color: #fff;
    text-align: center;
    margin: 0px 0px 15px;
    font-size: 18px;
  }

  p {
    font-size: 14px;
    opacity: 0.75;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;

    &:nth-child(3n) {
      margin-right: auto;
    }

    h4 {
      font-size: 16px;
    }

    p {
      font-size: 12px;
    }
  }
}

.star-rating {
  max-width: 30px;
  margin-right: 7px;
  color: rgb(255, 210, 10);
  display: inline;

  &:last-child {
    margin-right: 0;
  }
}
</style>
